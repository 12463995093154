import React, { FC } from "react";

const company = { name: "Refactoring", url: "https://refactoring.do" };

export const Footer: FC = () => (
  <>
    <footer className="fixed left-0 bottom-4 w-full text-center">
      <p className="text-[#a74f51] opacity-80">
        Buenas Vibras Tattoos © {new Date().getFullYear()} | Designed with ❤️ by
        <b>
          <a href={company.url}> {company.name}</a>
        </b>
      </p>
    </footer>
  </>
);
