import ReactDOM from "react-dom/client";
import React from "react";

import { Footer } from "./components/footer/footer";
import { Header } from "./components/header/header";
import "./index.css";
import { Body } from "./components/body/body";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Header />
    <Body />
    <Footer />
  </React.StrictMode>
);
