import { motion } from "framer-motion";
import React, { FC } from "react";

import { networks } from "./social-media.data";
import "./social-media.css";

const buttonStyle =
  "mr-3 p-4 font-semibold text-white inline-flex items-center space-x-2 rounded-xl border-2 border-white";

const navigate = (url: string) => () => (window.location.href = url);

export const SocialMedia: FC = () => (
  <>
    <div className="flex-none mt-5">
      {!!networks.length &&
        networks.map((network) => (
          <motion.button
            className={buttonStyle}
            onClick={navigate(network.link)}
            key={network.id}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <svg
              className="w-7 h-7 fill-current"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox={network.viewBox ?? "0 0 24 24"}
            >
              {network.path.map((p, i) => (
                <path key={network.id + i} d={p} />
              ))}
            </svg>
            {/* <span>{network.name}</span> */}
          </motion.button>
        ))}
    </div>
  </>
);
