import React, { FC } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";

import { SocialMedia } from "../social-media/social-media";

export const Body: FC = () => {
  const x = useMotionValue(0);
  // const y = useMotionValue(0);

  const background = useTransform(
    x,
    [-100, 0, 100],
    ["#E25A25", "#ffaeb0", "#F7B501"]
  );

  return (
    <>
      <motion.main
        className="h-screen flex flex-col pt-[38%] md:pt-80 items-center"
        style={{ background }}
      >
        <motion.div
          className="max-w-[200px] flex mb-7"
          drag
          dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
          style={{ x }}
          whileDrag={{
            rotate: [0, 180],
            transition: { duration: 0.7 },
          }}
          whileHover={{
            rotate: [0, 180],
            transition: { duration: 0.6 },
          }}
          whileTap={{
            scale: 0.9,
          }}
        >
          <img src="/logo512.png" alt="Logo" height="192" width="192" />
        </motion.div>

        <p className="text-3xl text-white pb-3">Coming soon...</p>
        <SocialMedia />
      </motion.main>
    </>
  );
};
